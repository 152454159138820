import Service from "./config/ConfigRepositoryAuth";

export default {
  async create(plan) {
    return await Service.post(`plans`, plan);
  },
  async getAll() {
    return await Service.get(`plans`);
  },
  async findByType(type) {
    return await Service.get(`plans?type=${type}`);
  },
  async assignHomePlan(data) {
    return await Service.post(`purchases_home`, data);
  },
  async delete(id) {
    return await Service.delete(`plans/${id}`);
  },
  async update(plan) {
    return await Service.put(`plans/${plan.code}`, plan);
  },
  async toPay(idBill) {
    return await Service.put(`purchases/${idBill}/pay`);
  }
};
