<template>
  <section class="white">
    <div
      class="d-flex justify-space-between align-center pr-14 pl-14 pt-8 pb-8"
    >
      <h1 class="primary--text text-h6 font-weight-black">
        NUEVO PLAN DE INTERNET
      </h1>
      <v-btn
        class="pl-6 pr-6"
        outlined
        color="primary"
        @click="$emit('changeComponent', 'InternetPlansTable')"
      >
        REGRESAR
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="pr-14 pl-14 mt-8 mb-8">
      <form>
        <v-row>
          <v-col cols="4">
            <div>
              <p class="text-subtitle-2 font-weight-regular mb-2">Nombre</p>
              <v-text-field
                v-model="internetPlan.name"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el nombre del plan "
                outlined
              ></v-text-field>
            </div>
            <div class="mt-3">
              <p class="text-subtitle-2 font-weight-regular mb-2">
                Precio (mensual)
              </p>
              <v-text-field
                v-model="internetPlan.price"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el costo del plan por mes"
                outlined
              ></v-text-field>
            </div>
            <div class="mt-3">
              <p class="text-subtitle-2 font-weight-regular mb-2">
                Velocidad (en megabytes MB)
              </p>
              <v-text-field
                v-model="internetPlan.speed"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba la velocidad en MB"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="8" class="pl-10">
            <div>
              <p class="text-subtitle-2 font-weight-regular mb-2">
                Descripción
              </p>
              <v-textarea
                v-model="internetPlan.description"
                class="rounded-0"
                rows="10"
                no-resize
                dense
                placeholder="Escriba una descripción sobre el plan"
                outlined
              ></v-textarea>
            </div>
          </v-col>
          <v-col cols="12" class="pt-0 d-flex flex-row-reverse">
            <v-btn v-if="!isEdit" color="primary" depressed @click="createPlan"
              >GUARDAR PLAN</v-btn
            >
            <v-btn v-if="isEdit" color="primary" depressed @click="updatePlan"
              >GUARDAR CAMBIOS</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </div>
    <div class="pl-14 pb-16">
      <v-img
        class="img--container"
        src="@/assets/icons/icon-internet-home-aqua.svg"
      >
      </v-img>
    </div>
  </section>
</template>

<script>
// Domains
import Plan from "../../domain/Plans";

export default {
  name: "InternetPlansNew",
  mounted() {
    this.changeEdit();
  },
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    isLoading: false,
    internetPlan: {
      name: "",
      price: "",
      speed: "",
      description: "",
      type: "HOGAR",
    },
  }),
  methods: {
    changeEdit() {
      if (this.isEdit) {
        this.internetPlan = this.item;
      }
    },
    async createPlan() {
      try {
        this.isLoading = false
        const response = await Plan.create(this.internetPlan);
        console.log(response);
        this.$emit("changeComponent", "InternetPlansTable");
      } catch (error) {
        console.log(error);
      }finally{
        this.isLoading = false
      }
    },
    async updatePlan() {
      try {
        const response = await Plan.update(this.internetPlan);
        console.log(response);
        this.$emit("changeComponent", "InternetPlansTable");
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img--container {
  width: 8rem;
}
</style>