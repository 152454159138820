import PlansRepository from "../repository/Plans.repository";

export default class Plans {
  static async create(plan) {
    return new Promise((resolve, reject) => {
      PlansRepository.create(plan)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async findByType(type) {
    return new Promise((resolve, reject) => {
      PlansRepository.findByType(type)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async assignHomePlan(data) {
    return new Promise((resolve, reject) => {
      PlansRepository.assignHomePlan(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async toPay(idBill) {
    return new Promise((resolve, reject) => {
      PlansRepository.toPay(idBill)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async getAll() {
    return new Promise((resolve, reject) => {
      PlansRepository.getAll()
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async update(plan) {
    return new Promise((resolve, reject) => {
      PlansRepository.update(plan)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async delete(id) {
    return new Promise((resolve, reject) => {
      PlansRepository.delete(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
}
